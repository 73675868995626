@import '../variables';
@import '~bootstrap/scss/mixins/breakpoints';

.product-search-large--container{
    padding: 2rem 1.2rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    @include media-breakpoint-up(md) {
        padding: 3.5rem;
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    background-image: linear-gradient(#97B3C9, #547793);
    border: 1px solid #8DA0AF;
    border-radius: 0.5rem;
    @include font-wide;
}

.product-search-large--caption{
    font-size: 1.9rem;
    padding-bottom: 0.8rem;
    margin-bottom: 1.8rem;
    font-weight: 700;
    color: white;
    border-bottom: 2px solid #5C788D;
    text-align: center;
    @include media-breakpoint-up(md) {
        font-size: 3rem;
        padding-bottom: 1rem;
        margin-bottom: 2.5rem;
    }
}

.product-search-large--button{
    color: white;
    font-size: 1.5rem;
    font-weight: 700;
    padding: 0.6rem 3rem;
    margin-top:2rem;
}