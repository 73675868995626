@import './../variables';

.comments{
    background-color: white;
    margin: 3em 0;
    padding: 0 2em;
    position: relative;

    &__header{
        margin-top: 2em;
        margin-bottom: 1.5em
    }

    &__date{
        position: relative;
        font-size: 0.9em;
        font-style: italic;
        padding-left:1.3em;
    }

    &__date::before{
        content: '';
        display: block;
        position: absolute;
        width: 1.1em;
        height: 1.1em;
        left:0;
        top: 0.1em;
        background-image: url('./../img/clock.svg');
    }

    &__record+&__record{
        margin-top: 2em;
    }

    &__btn{
        color: white;
        padding-left: 1.3rem;
        padding-right: 1.3rem;
        border-radius: 3rem;
        font-weight: 600;
        a {
            color: white;
        }
        a:hover {
            text-decoration: none;
        }
    }
}

.comments::after{
    content: '';
    position: absolute;
    background-color: transparent;
    display: block;
    bottom: 0rem;
    left: 2rem;
    height: 1rem;
    width: calc(100% - 4rem);
    box-shadow: 0 0 3rem rgba(0, 173, 241, 0.5);
    transition: box-shadow 0.25s;
    z-index: -1;
}

@include media-breakpoint-down(sm) {
    .comments{
        padding:0 15px;
    }
}

.comments--modal-contents{
    font-size: 0.7rem;
    h4{
        font-size: 1rem;
        font-weight: 600;
    }

}