@import './../variables';

.store-info--canvas{
    background-color: white;
}

.store-info--desktop{
    margin:0 15px;
}

.store-info--caption {
    font-weight: 700;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
}

.store-info--header {
    background-color: #D7E9F7;
    text-align: center;
    font-weight: 700;
    color: #677987;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
}

.store-info--row {
    background-color: white;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.store-info--row-cell {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.store-info--rating-count {
    font-size: 0.8rem;
    color: #E5E5E5;
}

.store-info--logo {
    max-width: 100%;
    max-height: 4rem;
}

.store-info--trust-score{
    font-size: 1.25rem;
    color: $primary;
    font-weight: bold;
}

.store-info--reviews {
    font-size: 1.25rem;
    color: #217fff;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}

.store-info--reviews:hover {
    color: darken(#217fff, 10%);
}

.store-info-verified {
    height: 2.5rem;
}

.store-info--shop-btn {
    color: white;
    font-weight: 800;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 2rem;
}

.store-info--mobile {
    @include media-breakpoint-up(md) {
        display: none;
    }
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin: 0;
}

.store-info--mobile-metrics {
    display: flex;
    flex: 1;
    padding-right: 15px;
    padding-left: 15px;
    justify-content: space-between;
    margin-top: 1rem;

}

.store-info--mobile-metrics-label {
    font-size: 0.85rem;
    font-weight: 300;
    text-align: center;
}

.store-info--mobile-metrics-value {
    height: 3rem;

    img {
        height: 2rem;
    }
}