@import '../variables';
@import '~bootstrap/scss/mixins/breakpoints';

.store-header--logo {
    background-color: #0B1023;
    padding: 1rem 0;

    img {
        height: 1.8rem;
    }
}

.store-header--logo-img{
    cursor: pointer;
}