.search-overlay {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: -1;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    // height: calc(100% - 3.8rem);
    height: 100%;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.4);
    transition: opacity 0.25s ease-in-out;
}

.search-overlay-fade-in {
    opacity: 1;
    z-index: 9999;
}

.search-overlay-loader {
    display: inline-block;
    width: 64px;
    height: 64px;
}

.search-overlay-loader:after {
    content: " ";
    display: block;
    width: 5rem;
    height: 5rem;
    ;
    margin: 1px;
    border-radius: 50%;
    border: 0.4rem solid #fff;
    border-color: rgba(255, 2555, 2555, 0.8) transparent rgba(255, 2555, 2555, 0.8) transparent;
    animation: search-overlay-loader-anim 1.2s linear infinite;
}

@keyframes search-overlay-loader-anim {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}