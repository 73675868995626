@import './../variables';

.information {
    h1 {
        @include media-breakpoint-up(md) {
            font-size: 3.35rem;
        }

        font-weight: 800;
        margin-top: 3rem;
    }

    h2 {
        font-weight: 800;
        margin-top: 3rem;
    }

    h5 {
        font-weight: 800;
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
    }
}