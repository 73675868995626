$grid-breakpoints: (xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px);

$container-max-widths: (sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px) !default;

$green: #9fd954;
$orange: #FF890B;

$primary: $orange;

$border-radius: .5rem;
$border-radius-lg: .5rem;
$border-radius-sm: .2rem;

// $font-family-sans-serif: 'Nunito Sans', sans-serif;
$font-family-sans-serif: 'PT Sans', sans-serif;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import "~bootstrap/scss/mixins";

@mixin font-wide {
  font-family: 'Nunito Sans', sans-serif;
}
