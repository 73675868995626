@import './../variables';

.results-notfound {
    background-color: #F4F7FC;
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
}

.results-notfound--container{
    background-color: white;
    padding: 1rem;
    @include media-breakpoint-up(md){
        padding: 2.5rem;
    }
}

.results-notfound--img{
    width: 3rem;
    color: $red;
}

.results-notfound--caption__red{
    color: $red;
    font-weight: 800;
}