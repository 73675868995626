@import '../variables';
@import '~bootstrap/scss/mixins/breakpoints';

.product-search{
    @include font-wide;
}

.product-search--hint {
    background-color: white;
    padding: 0.2rem 1.3rem;
    border-radius: 5rem;
    display: inline-block;
    position: relative;
    margin-top: 2rem;
    margin-bottom: 1.3rem;

    @include media-breakpoint-up(md) {
        margin-left: 2rem;
    }
}

.product-search--verified {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    height: 2rem;
    z-index: 100;
}

.product-search--hint::after {
    content: '';
    display: block;
    position: absolute;
    width: 1rem;
    height: 1rem;
    bottom: -2rem;
    left: 2rem;
    border: 1rem solid transparent;
    border-top: 1rem solid white;
}

.product-search--search {
    display: flex;
    margin-bottom: 1.3rem;
    flex-direction: column;

    input[name="product-search"],
    input[name="region-search"] {
        margin-bottom: 1rem;
    }
    .rbt {
        position: relative;
    }

    @include media-breakpoint-up(md) {
        flex-direction: row;

        .rbt {
            flex: 1;
        }

        input[name="product-search"],
        input[name="region-search"] {
            margin-bottom: 0;

        }

        input[name="product-search"] {
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        }

        input[name="region-search"] {
            border-radius: 0;
            border-left: none;
        }
    }

}

.product-search--button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include media-breakpoint-up(md) {
        text-align: left;
        display: block;
    }
}

.product-search--button {
    color: White;
    font-weight: 700;
    font-size: 1.4rem;
    padding-left: 2.5rem;
    padding-right: 2.5rem;

    @include media-breakpoint-up(md) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
}

.product-search--secure {
    display: inline-block;
    font-weight: 600;

    img {
        height: 1.5rem;
        margin-right: 0.25rem;
    }
}