@import './../variables';

.result-variant--caption {
    font-weight: 700;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
}

.result-variant--row {
    background-color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;

}

.result-variant--row-cell {
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.result-variant--name {
    font-weight: 700;
    font-size: 1.3rem;
    text-align: center;
    line-height: 120%;
}

.result-variant--description {
    font-size: 0.8rem;
    font-weight: 700;
    text-align: center;
    line-height: 100%;
}

.result-variant-prod-img{
max-height: 6rem;
    max-width: 100%;
}

.result-variant--tag {
    padding: 0.8rem;
    border: 2px solid #D5D5D5;
    border-radius: 1rem;
    text-align: center;
}

.result-variant--tag-prev {
    color: #858585;
    font-weight: 300;
    font-size: 0.8rem;
}

.result-variant--tag-prev__strike {
    text-decoration: line-through;
    white-space: nowrap;
}

.result-variant--tag-current {
    font-weight: 700;
    font-size: 1.35rem;
    line-height: 100%;
    margin-top: 0.5rem;
    white-space: nowrap;
}

.result-variant--tag-current::before {
    content: '';
    display: block;
    width: 50%;
    transform: translateX(50%);
    border-top: 2px solid #D5D5D5;
    margin-bottom: 0.5rem;
}

.result-variant--tag-units {
    font-size: 0.7rem;
    line-height: 100%;
}

.result-variant--discount {
    font-weight: 700;
    color: $red;
    font-size: 1.2rem;
}

.result-variant--shipping1 {
    color: $green;
    font-weight: 700;
    font-size: 1.35rem;
    text-align: center;
    line-height: 130%;
}

.result-variant--shipping2 {
    font-weight: 300;
    font-size: 0.7rem;
    text-align: center;
    line-height: 100%;
}

.result-variant--shop-btn {
    color: white;
    background-color: $green;
    font-weight: 800;
    padding-left: 1rem;
    padding-right: 1rem;
    border-radius: 2rem;
}

.result-variant--shop-btn:hover {
    background-color: darken($green, 10%);
    color: white;
}