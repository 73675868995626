@import './../variables';

.results-completed{
    background-color: white;
    padding: 1rem;
    @include media-breakpoint-up(md){
        padding: 2.5rem;
    }
    font-family: 'PT Sans', sans-serif;
}

.results-completed--finder-img{
    width: 4rem;
    @include media-breakpoint-up(md){
        width: 5.5rem;
        display:block;
    }
}
.results-completed--finder-img-inline{
    display: inline-block;
    height: 3rem;
    margin-right: 0.3rem;
    @include media-breakpoint-up(md){
        display:none;
    }
}

.results-completed--caption{
    font-weight: 800;
    font-size: 2;
    @include media-breakpoint-up(md){
        font-size: 2.5;
    }
}

.results-completed--caption__primary{
    color: $primary;
}