@import '../variables';

.footer {
    font-size: 0.8rem;
    text-align: center;
    margin-top: 4rem;
    margin-bottom: 1rem;
}

.footer--copyright {
    font-weight: 700;
}

.footer--links {
    a {
        color: $body-color;
        font-weight: 700;
    }
}

.footer--disclaimer {
    margin-top: 1rem;
    color: #D1D1D1;
}

.footer--modal-contents{
    font-size: 0.7rem;
    h1 {
        font-size: 1.3rem;
        font-weight: 600;
    }
    h2{
        font-size: 1.1rem;
        font-weight: 600;
    }
    h3{
        font-size: 1rem;
        font-weight: 600;
    }
}